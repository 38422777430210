import React, { useState, useEffect, useRef, useContext } from "react"
import "./login.css"
import logo from "../../assets/images/logo-red.png"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, loginStatus, loginRequest } from "../../actions/userActions";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import LogoLogin from "../../assets/images/logo-login.png"
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { LangContext } from "../../app";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const LoginCenter = () => {
    const { t } = useTranslation('common')
    const [form, setForm] = useState({
        email: "",
        password: "",
        checkbox: true,
    });

    const createRef = useRef(null)

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const { isAuthenticated, error } = auth;

    const { email, password, checkbox } = form;
    const [isShow, setIsShow] = useState()
    const [lango, setLango] = useContext(LangContext)
    const [smallHeight, setSmallHeight] = useState(false)
    const [typePassword, setTypePassword] = useState("password")

    const changePasswordType = () => {
        console.log(typePassword)
        if (typePassword === "password") {
            setTypePassword("text")
        } else {
            setTypePassword("password")
        }
    }

    const history = useHistory();

    const redirect = location.search ? location.search.split("=")[1] : "/";

    localStorage.getItem("clubID") !== null ? localStorage.removeItem("clubID") : ""

    useEffect(() => {
        if (userInfo !== null) {
            dispatch(loginStatus());
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.innerHeight < 775 && this.window.innerWidth >= 992) {
                setSmallHeight(true)
            } else {
                setSmallHeight(false)
            }
        })
    }, [])

    if (isAuthenticated) {
        history.push("/club");
    }

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(login(email, password));
    };
    const onChange = (e) => {
        if (e.target.name === "remember") {
            setForm({ ...form, checkbox: !checkbox })
            return;
        }
        setForm({ ...form, [e.target.name]: e.target.value });
    };
    const closeAlert = () => {
        dispatch(loginRequest())
    }

    const showLanguage = (e) => {
        e.preventDefault()
        setIsShow(!isShow)
    }

    const getLanguage = (e, lng) => {
        e.preventDefault()
        i18n.changeLanguage(lng)
        setLango({ title: e.target.innerText, flag: lng })
        setIsShow(!isShow)
    }
    return (
       <div className="login-page d-flex justify-content-center align-items-center vh-100">
            <div className="login-container text-center p-4 shadow-lg rounded bg-white">
                <div className="image mb-3">
                    <img width="500px" src={logo} alt="Logo" />
                </div>
                <br/><br/>

                <div className="box-content">
                    
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label><b>{t('Email address')}</b></label>
                            <input
                                className="form-control"
                                onChange={onChange}
                                value={email}
                                type="email"
                                name="email"
                                id="emailaddress"
                                placeholder={t('example@email.com')}
                            />
                        </div>
                        <div className="form-group">
                            <label><b>Password</b></label>
                            <div className="input-group">
                                <input
                                    onChange={onChange}
                                    value={password}
                                    className="form-control"
                                    type={typePassword}
                                    name="password"
                                    id="password"
                                    placeholder={t('enter password')}
                                />
                                <div className="input-group-append">
                                    <span onClick={changePasswordType} className="input-group-text" style={{ cursor: "pointer" }}>
                                        <i className={typePassword === "text" ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group form-check d-flex align-items-center">
                            <input
                                onChange={onChange}
                                type="checkbox"
                                name="remember"
                                checked={checkbox}
                                className="form-check-input"
                                id="checkbox-signin"
                                style={{ marginRight: "5px" }} // Ensure spacing
                            />
                            <label className="form-check-label" htmlFor="checkbox-signin">
                                {t('Remember me')}
                            </label>
                        </div>

                        <button className="btn btn-primary btn-block" type="submit">
                            <i className="fa fa-sign-in"></i> {t('Login')}
                        </button>

                        <p className="mt-3">
                            {t("Don't have an account?")} <a href="/signup" className="signup-link">{t('Sign Up')}</a>
                        </p>
                        <br/>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default LoginCenter