import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import i18n from "i18next";
import { ProgressBar } from 'react-bootstrap'
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import { useDispatch } from "react-redux";
import { loginStatus } from "../../actions/userActions";
import { InputWithErrors } from "../../components/Basic/Input/InputWithErrors";
import SelectWithErrors from "../../components/Basic/Select/SelectWithErrors";
import { Bars } from "react-loader-spinner";
import generateString from "../../customFunction/generateString";
import "./add.css"

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

const AddScenario = (async) => {
  const { t } = useTranslation('common')
  const [folders, setFolders] = useState([]);
  const [tags, setTags] = useState([]);
  const [ea5File, setEa5File] = useState();
  const [sourceFile, setSourceFile] = useState()
  const [metaFile, setMetaFile] = useState()
  const [eventFile, setEventFile] = useState()
  const [imageFile, setImageFile] = useState();
  const [progress, setProgress] = useState(0)
  const history = useHistory()
  const [errors, setErrors] = useState([])
  const [fileLength, setFileLength] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [timeStamps, setTimeStamps] = useState({ minutes: 0, second: 0 })
  const [startTime, setStartTime] = useState({ minutes: "", seconds: "" })
  const [endTime, setEndTime] = useState({ minutes: "", seconds: "" })
  const [warningMessage, setWarningMessage] = useState("")
  const [warningColor, setWarningColor] = useState("red")
  const [isServerError, setIsServerError] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const [duration, setDuration] = useState(null)
  const { folderID } = useParams()

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const token = userInfo !== null ? userInfo.token : null
  const uid = userInfo !== null ? userInfo.user_id : null;

  const dispatch = useDispatch()
  let parentFolder = []

  const configJson = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  };

  const [form, setForm] = useState({
    name: "",
    folder_id: folderID,
    favorite: false,
    source: "0",
    scenarioType: "0",
    startTime: "",
    endTime: "",
    description: "",
    tag: null,
    calibratorKind: "Scouting"
  });

  useEffect(() => {
    const getFolders = async () => {
      try {
        const folders = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/user/${uid}`,
          configJson
        );

        folders.data.map(folder => {
          if (folder.parent_id === 0) {
            parentFolder.push(folder)
          }
        })

        folders.data.map(folder => {
          if (folder.parent_id !== 0) {
            parentFolder.map(parent => {
              if (parent.folder_id === folder.parent_id) {
                folder.name = `${parent.name}/${folder.name}`
                return folder
              }
            })
          } else {
            return folder
          }
        })

        setFolders(folders.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(loginStatus())
        }
      }
    };
    getFolders();
  }, [tags]);

  const uploadFile = async (e) => {
    setWarningMessage('')
    if (e.target.files && e.target.files.length === 1) {
      if (e.target.name === "meta") {
        setMetaFile(e.target.files[0])
      } else if (e.target.name === "event") {
        setEventFile(e.target.files[0])
      } else {
        if (form.source === "saivavt") {
          getSaivaVTLength(e)
        } else if (form.source === "ea5") {
          getEa5Length(e)
        } else if (form.source === "metrica") {
          getMetricaLength(e)
        } else if (form.source === "sportec") {
          getSportectLength(e)
        }
        setSourceFile(e.target.files[0])
      }
    }
  }

  useEffect(() => {

  }, [duration])

  const getSportectLength = (e) => {
    e.preventDefault()
    const fileName = e.target.files[0].name
    const lengthFile = fileName.split(".")[0].split("_")[2].split("-")
    const frameLength = (Number(lengthFile[1]) - Number(lengthFile[0])) / 50
    console.log(frameLength)
    if (frameLength > 60) {
      setWarningMessage('Scenario should be no longer than 1 minute.')
    }
  }

  const getEa5Length = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const isball = JSON.parse(text).sceneObjects.some(obj => {

        return /\b\w*ball\w*\b/g.test(obj.type)
      })

      !isball ? setWarningMessage('Ball does not exist, please upload another scenario') : setWarningMessage('')

      const fileLength = JSON.parse(text).sceneCount
      setTimeStamps({ minutes: padTo2Digits(Math.floor(fileLength / 60)), second: padTo2Digits(fileLength % 60) })
    }
    reader.readAsText(e.target.files[0])
  }

  const getMetricaLength = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const rowCount = text.split('\n')
      const fileLength = Math.round((rowCount.length - 1) / 25)
      setTimeStamps({ minutes: padTo2Digits(Math.floor(fileLength / 60)), second: padTo2Digits(fileLength % 60) })
    };
    reader.readAsText(e.target.files[0])
  }

  const getSaivaVTLength = (e) => {
    e.preventDefault()

    const file = e.target.files[0]
    if (file) {
      const video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = () => {
        console.log(video.duration)
        if (video.duration > 60) {
          setWarningMessage('Scenario should be no longer than 1 minute.')
        }
        URL.revokeObjectURL(video.src)
      }

      video.src = URL.createObjectURL(file)
    }
    // const reader = new FileReader()
    // reader.onload = async (e) => {
    //   const text = (e.target.result)
    //   const fileLength = (JSON.parse(text).Players[0].maxFrameNumber) / 5
    //   setTimeStamps({ minutes: padTo2Digits(Math.floor(fileLength / 60)), second: padTo2Digits(fileLength % 60) })
    // };
    // reader.readAsText(e.target.files[0])
  }

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setImageFile(e.target.files[0])
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const sTime = (parseInt(startTime.minutes) * 60) + parseInt(startTime.seconds)
    const eTime = (parseInt(endTime.minutes) * 60) + parseInt(endTime.seconds)

    const formData = new FormData()

    formData.append('image', imageFile)
    formData.append('file', sourceFile)
    formData.append('metaFile', metaFile)
    formData.append('eventFile', eventFile)
    formData.append('scenario_id', generateString(28).trim())
    formData.append('name', form.name)
    formData.append('fileName', sourceFile.name)
    formData.append('folder_id', form.folder_id)
    formData.append('favorite', form.favorite)
    formData.append('calibrator', form.calibratorKind)
    formData.append('server_id', 4)
    formData.append('source', form.source)
    formData.append('scenarioType', form.scenarioType)
    formData.append('startTime', sTime)
    formData.append('endTime', eTime)
    formData.append('description', form.description)
    formData.append('tags', tags.toString())

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }

    try {
      setIsLoading(true)
      setIsSaveDisabled(true)
      const scenario = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: data => {
          setProgress(Math.round((100 * data.loaded) / data.total))
        }
      })

      if (form.source !== 'saivavt') {
        console.log('calll saica')
        const saicaData = {
          name: scenario.data.name,
          fileName: sourceFile.name,
          scenario_id: scenario.data.scenario_id,
          scenarioType: scenario.data.scenarioType,
          source: scenario.data.source
        }
        const saica = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/saica`, saicaData, configJson)
      }

      setIsLoading(false)
      setIsSaveDisabled(false)
      history.push('/scenarios')
    } catch (error) {
      setIsLoading(false)
      setErrors(error.response.data)
      if (error.response.status === 500) {
        setIsServerError(true)
      }
    }
  };

  const onChangeStartTime = async (e) => {
    let value = 0

    const regexPattern = /^[0-9]*$/;

    if (regexPattern.test(e.target.value)) {
      if (e.target.value > 60) {
        value = 60
      } else {
        value = e.target.value
      }
      setStartTime({ ...startTime, [e.target.name]: value })
    }
  }
  const onChange = async (e) => {
    setWarningMessage('')
    if (e.target.name === "favorite") {
      setForm({ ...form, favorite: !form.favorite });
      return
    }

    if (e.target.value === "saivavt" || e.target.value === 'metrica' || e.target.value === 'sportec') {
      setForm({ ...form, [e.target.name]: e.target.value, scenarioType: 'match' });
      return
    }

    if (e.target.name !== "scenarioType" && e.target.value === "ea5") {
      setForm({ ...form, [e.target.name]: e.target.value, scenarioType: '0' });
      return
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const addTags = (e) => {
    e.persist();
    if (e.key === "Enter") {
      if (tags.indexOf(e.target.value) === -1) {
        (async () => {
          await setTags((arr) => [...arr, e.target.value]);
          e.target.value = null
        })()
      } else {
        e.target.value = null
      }
    }
  };

  const removeTag = (index) => {
    const arr = [...tags]
    arr.splice(index, 1)
    setTags(arr)
  }

  useEffect(() => {
    const sTime = (parseInt(startTime.minutes) * 60) + parseInt(startTime.seconds)
    const eTime = (parseInt(endTime.minutes) * 60) + parseInt(endTime.seconds)

    if (eTime < sTime) {
      setWarningMessage('Your duration is invalid')
      setWarningColor("red")
    }
    else if (eTime - sTime > 300) {
      setWarningMessage('Scenario length cannot more than 5 minutes')
      setWarningColor("red")
    } else if (eTime > sTime) {
      setWarningMessage('Your duration is valid')
      setWarningColor("green")
    }
  }, [startTime, endTime])

  const onChangeEndTime = async (e) => {
    let value = 0;

    const regexPattern = /^[0-9]*$/;

    if (regexPattern.test(e.target.value)) {
      if (e.target.value > 60) {
        value = 60
      } else {
        value = e.target.value
      }
      setEndTime({ ...endTime, [e.target.name]: value })
    }
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const getLabelName = (label) => {
    switch (label) {
      case "saivavt":
        return "Video File"
      case "ea5":
        return "EA5 File"
      case "sportec":
        return "Clipper Sportec File"
    }
  }

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      {/* <div style={{ display: isLoading ? "block" : "none", position: "absolute", top: "29rem", left: "51.4rem", zIndex: 1, color: "#60a5fa" }}>
        <Bars
          height="80"
          width="80"
          color="#2563eb"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <p style={{ textAlign: "center", fontSize: "20px" }}>Processing....</p>
      </div> */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{t('ADMIN_HOME')}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/scenarios">{t('Scenarios')}</Link>
                </li>
                <li className="breadcrumb-item active">{t('Add scenario')}</li>
              </ol>
            </div>
            <h4 className="page-title">{t('Add scenario')}</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div style={{ display: isServerError ? "block" : "none" }} class="alert alert-danger" role="alert">
            {errors.message}
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={onSubmit} className="form-horizontal">
                <fieldset>
                  <legend>{t('Add scenario')}</legend>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">{t('Name')}:</label>
                    <div className="col-8">
                      <InputWithErrors
                        name="name"
                        value={form.name}
                        onChange={onChange}
                        errors={errors}
                        placeholder="Scenario Name ..."
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">{t('Folder')}:</label>{" "}
                    <div className="col-8">
                      <SelectWithErrors
                        name="folder_id"
                        value={form.folder_id}
                        errors={errors}
                        data={folders}
                        keyPair={{ key: 'folder_id', val: 'name' }}
                        onChange={onChange}
                        intialSelect={{ key: 0, val: t('Rootfolder') }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">{t('Favorite')}:</label>{" "}
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="checkbox"
                        name="favorite"
                        value={form.favorite}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">{t('Description')}:</label>{" "}
                    <div className="col-8">
                      <textarea
                        className="form-control"
                        cols="50"
                        rows="4"
                        name="description"
                        value={form.description}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">{t('Tags')}:</label>{" "}
                    <div className="col-8">
                      <div className="form-control bootstrap-tagsinput">
                        {tags.map((tag, index) => (
                          <span key={index} style={{ marginLeft: "5px" }} className="tag badge badge-info">
                            {tag}<button onClick={() => removeTag(index)} type="button" style={{ fontWeight: 700, fontSize: "13px", border: "none", backgroundColor: "transparent", color: "white" }}>x</button>
                          </span>
                        ))}
                        <input
                          name="addTags"
                          onKeyDown={addTags}
                          style={{ border: "none", outline: "none" }}
                          type="text"
                          className="allow-enter"
                          placeholder=""
                        />
                      </div>
                      <input
                        data-role="tagsinput"
                        name="tags"
                        type="text"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  <div className="form-group row scenario-type">
                    <label htmlFor="" className="first-label col-3 col-form-label">Source</label>
                    <div className="content col-8">
                      <SelectWithErrors
                        name="source"
                        value={form.source}
                        errors={errors}
                        data={
                          [
                            { "id": "saivavt", "name": "Video" },
                            { "id": "ea5", "name": "Easy Animation" },
                            { "id": "sportec", "name": "Sportec" }
                          ]
                        }
                        keyPair={{ key: "id", val: "name" }}
                        onChange={onChange}
                        className="form-control source col-4"
                        intialSelect={{ key: "0", val: "--- Please Select Source Type ---" }}
                        style={{ fontSize: "0.8rem" }}
                        errorStyle={{ fontSize: "0.76rem" }}
                      />
                      <div className="type form-group row col-8">
                        <label htmlFor="" className="col-4 col-form-label">Scenario Type</label>
                        <div className="type-content col-8">
                          <SelectWithErrors
                            name="scenarioType"
                            value={form.scenarioType}
                            errors={errors}
                            data={[{ "id": "match", "name": "Match" }, { "id": "training", "name": "Training" }]}
                            keyPair={{ key: "id", val: "name" }}
                            onChange={onChange}
                            className="form-control"
                            intialSelect={{ key: "0", val: "--- Please Select Scenario Type ---" }}
                            style={{ fontSize: "0.8rem" }}
                            errorStyle={{ fontSize: "0.76rem" }}
                            disabled={form.source === "ea5" ? false : true}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="type-small form-group row">
                    <label htmlFor="" className="col-3 col-form-label">Scenario Type</label>
                    <div className="type-content col-8">
                      <SelectWithErrors
                        name="scenarioType"
                        value={form.scenarioType}
                        errors={errors}
                        data={[{ "id": "match", "name": "Match" }, { "id": "training", "name": "Training" }]}
                        keyPair={{ key: "id", val: "name" }}
                        onChange={onChange}
                        className="form-control"
                        intialSelect={{ key: "0", val: "--- Please Select Scenario Type ---" }}
                        style={{ fontSize: "0.8rem" }}
                        errorStyle={{ fontSize: "0.76rem" }}
                        disabled={form.source === "ea5" ? false : true}
                      />
                    </div>
                  </div>
                  {form.source != 0 && <div className="form-group row tracking-file">
                    <label className="first-label col-3 col-form-label">{getLabelName(form.source)}</label>
                    <div className="col-8 content">
                      <div className="col-12 first-row">
                        <div className="col-5 left">
                          <input
                            onChange={uploadFile}
                            disabled={form.source === "0" ? true : false}
                            type="file"
                            name="ea5"
                            accept=".ea5, .txt, .json, .zip, .mp4, .avi"
                            style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "filename" }) ? "1px red solid" : "" : "" }}
                          />
                          {errors.length > 0 ? errors.some((val) => { return val.path[0] === "filename" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "filename").message}</i></p> : ""}
                        </div>
                        {warningMessage && form.source !== 'saivavt' && <div className="right">
                          <p><i style={{ color: warningColor, fontSize: "14px", fontWeight: "bolder" }} className={`mr-1 fa ${warningColor === "green" ? "fa-check-circle" : "fa-exclamation-circle"} `}></i><span style={{ color: warningColor, fontStyle: 'italic', fontSize: '0.8rem' }}>{warningMessage}</span></p>
                        </div>}
                        {
                          form.source === 'saivavt' && <div className="col-7 right form-group row">
                            <label className="col-form-label col-5">Footage Type</label>
                            <div className="col-7">
                              <select value={form.calibratorKind} onChange={onChange} name="calibratorKind" id="" className="form-control">
                                <option value="Scouting">Scouting feed</option>
                                <option value="NewScouting">New Scouting feed (Beta)</option>
                                <option value="Broadcasting">Broadcast feed</option>
                              </select>
                            </div>
                          </div>
                        }
                      </div>
                      {warningMessage && form.source === 'saivavt' && <div className="right">
                          <p><i style={{ color: warningColor, fontSize: "14px", fontWeight: "bolder" }} className={`mr-1 fa ${warningColor === "green" ? "fa-check-circle" : "fa-exclamation-circle"} `}></i><span style={{ color: warningColor, fontStyle: 'italic', fontSize: '0.8rem' }}>{warningMessage}</span></p>
                        </div>}
                      {form.source === 'metrica' && <div className="second-row mb-2">
                        <div className="col-4 left">
                          <div className="time">
                            <label style={{ fontSize: "0.8rem" }} htmlFor="" className="pl-0 col-5 col-form-label mr-2">Start Time</label>
                            <input autoComplete="off" maxLength={2} style={{ width: '40px', border: errors.length > 0 ? errors.some(val => { return val.path[0] === "startTime" }) ? "1px red solid" : "" : "" }} value={startTime.minutes} onChange={onChangeStartTime} name="minutes" placeholder="00" type="text" className="form-control" />
                            <p style={{ fontSize: '1.2rem', fontWeight: 600 }}>:</p>
                            <input autoComplete="off" style={{ width: '40px', border: errors.length > 0 ? errors.some(val => { return val.path[0] === "startTime" }) ? "1px red solid" : "" : "" }} value={startTime.seconds} onChange={onChangeStartTime} name="seconds" placeholder="00" type="text" className="form-control" />
                          </div>
                          {errors.length > 0 ? errors.some((val) => { return val.path[0] === "startTime" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "0.73rem" }}><i>{errors.find(val => val.path[0] === "startTime").message}</i></p> : ""}
                        </div>
                        <div className="col-4 right">
                          <div className="time">
                            <label style={{ fontSize: "0.8rem" }} htmlFor="" className="pl-0 col-5 col-form-label mr-2">End Time</label>
                            <input autoComplete="off" maxLength={2} style={{ width: '40px', border: errors.length > 0 ? errors.some(val => { return val.path[0] === "endTime" }) ? "1px red solid" : "" : "" }} value={endTime.minutes} onChange={onChangeEndTime} name="minutes" placeholder="00" type="text" className="form-control" />
                            <p style={{ fontSize: '1.2rem', fontWeight: 600 }}>:</p>
                            <input autoComplete="off" style={{ width: '40px', border: errors.length > 0 ? errors.some(val => { return val.path[0] === "endTime" }) ? "1px red solid" : "" : "" }} value={endTime.seconds} onChange={onChangeEndTime} name="seconds" placeholder="00" type="text" className="form-control" />
                          </div>
                          {errors.length > 0 ? errors.some((val) => { return val.path[0] === "endTime" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "0.73rem" }}><i>{errors.find(val => val.path[0] === "endTime").message}</i></p> : ""}
                        </div>
                      </div>}
                    </div>
                  </div>}
                  {form.source === 'metrica' && <div className="metrica">
                    <div className="form-group row">
                      <label htmlFor="" className="col-form-label col-3">Meta File</label>
                      <div className="col-8">
                        <input
                          onChange={uploadFile}
                          type="file"
                          name="meta"
                          accept=".xml"
                          style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "metaFile" }) ? "1px red solid" : "" : "" }}
                        />
                        {errors.length > 0 ? errors.some((val) => { return val.path[0] === "metaFile" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "metaFile").message}</i></p> : ""}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="" className="col-form-label col-3">Event File</label>
                      <div className="col-xl-4 col-sm-2">
                        <input
                          onChange={uploadFile}
                          type="file"
                          name="event"
                          accept=".json"
                          style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "eventFile" }) ? "1px red solid" : "" : "" }}
                        />
                        {errors.length > 0 ? errors.some((val) => { return val.path[0] === "eventFile" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "filename").message}</i></p> : ""}
                      </div>
                    </div>
                  </div>}
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      Upload progress:
                    </label>{" "}
                    <div className="col-8">
                      <ProgressBar now={progress} label={`${progress}%`} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      Excercise {t('Image')}:
                    </label>{" "}
                    <div className="col-8">
                      <input onChange={uploadImage} type="file" name="image" accept="image/png, image/jpeg " />
                    </div>
                  </div>
                </fieldset>
                <button disabled={isSaveDisabled ? true : false} type="submit" className="btn button">
                  <i className="fa fa-fw fa-floppy-o"></i> {t('Save')}
                </button>
                <Link to="/scenarios" className="button-cancel">
                  <i className="fa fa-fw fa-ban"></i>&nbsp;{t('Cancel')}
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddScenario;
