import React, { createContext, useState } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Admin from "./pages/Admin";
import LoginCenter from "./pages/LoginCenter";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import UserRegistration from "./pages/Users/registration";

export const LangContext = createContext();

const App = () => {
  const [lango, setLango] = useState({ title: "English", flag: "en" });
  return (
    <Router>
      <LangContext.Provider value={[lango, setLango]}>
        <Switch>
          <Route exact path="/login" component={LoginCenter} />
          <Route exact path="/reset/:email/:token" component={ResetPassword} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/join/:token" component={UserRegistration} />
          <Route path="/" component={Admin} />
        </Switch>
      </LangContext.Provider>
    </Router>
  );
};

export default App;
