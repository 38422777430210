import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ProgressBar } from 'react-bootstrap'
import _ from "lodash";
import { useDispatch } from "react-redux";
import { loginStatus } from "../../actions/userActions";

const AddVideo = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const token = userInfo !== null ? userInfo.token : null;
  const uid = userInfo !== null ? userInfo.user_id : null
  const history = useHistory()
  const dispatch = useDispatch()

  const configJson = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'access',
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const [videoFile, setVideoFile] = useState(null);
  const [progress, setProgress] = useState(0)
  const [statusUpload, setStatusUpload] = useState("Waiting ...")
  const [servers, setServers] = useState([])
  const [folders, setFolders] = useState([])
  const [errors, setErrors] = useState({ filename: null, name: null, server_id: null })
  const [tags, setTags] = useState([])

  const [form, setForm] = useState(
    {
      name: "",
      calibrator_kind: "Scouting",
      folder_id: "",
      favorite: false,
      bodyAngle: false,
      server_id: "",
      tags: tags.toString(),
      correct: true,
      description: ""
    }
  );

  const loadFile = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setVideoFile(e.target.files[0]);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "favorite" || e.target.name === "correct" || e.target.name === "bodyAngle") {
      setForm({ ...form, [e.target.name]: !form[e.target.name] })
      return
    }
    setForm({ ...form, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: null })
  };

  useEffect(() => {
    const getServersAndFolders = async () => {
      try {
        const servers = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/server`, config)
        const folders = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/user/${uid}`, config)
        setForm({ ...form, server_id: servers.data[0].server_id, folder_id: folders.data.find(data => data.uid === uid).folder_id })
        console.log(servers.data)
        setServers(servers.data)
        setFolders(folders.data)
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(loginStatus())
        }
      }
    }

    getServersAndFolders()
  }, [])



  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const localError = {}

    // --- Form validation ---
    if (form.name === '') {
      localError.name = 'Name cannot be empty'
    }
    if (videoFile === null) {
      localError.filename = 'Video is empty'
    }
    if (form.server_id == 0) {
      localError.server_id = 'Server is empty'
    }
    if (!_.isEmpty(localError)) {
      setErrors(localError)
      return
    }
    // --- End Form Validation ---

    formData.append("file", videoFile);
    formData.append("ext", videoFile.name.split(".")[1])
    formData.append("calibrator", form.calibrator_kind);
    formData.append("name", form.name);
    formData.append("uid", uid);
    formData.append("folder_id", form.folder_id);
    formData.append("server_id", form.server_id);
    formData.append("favorite", form.favorite ? 1 : 0);
    formData.append("correct", form.correct ? 1 : 0);
    formData.append("body_angle", form.bodyAngle ? 1 : 0);
    formData.append("description", form.description);
    formData.append("tags", tags.toString())

    console.log(servers)

    try {
      const videoFiles = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/videotrackingai`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total))
          }
        }
      );

      history.push("/videotracking/tasks")
    } catch (error) {
      if (error.response.status === 400) {
        setErrors(error.response.data)
        return
      }
      setStatusUpload(error.response.data.message)
    }
  };

  const addTags = (event) => {
    event.persist()
    if (event.key === 'Enter') {
      if (tags.indexOf(event.target.value) === -1) {
        (async () => {
          await setTags(arr => [...arr, event.target.value])
          event.target.value = null
        })()
      } else {
        event.target.value = null
      }
    }
  }
  const removeTag = (idx) => {
    const arr = [...tags]
    arr.splice(idx, 1)
    setTags(arr)
  }
  
  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Video Tracking AI</li>
                <li className="breadcrumb-item active">Add video</li>
              </ol>
            </div>
            <h4 className="page-title">Add video</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <span className="help" title=".mp4,.mov,..">
                    Video file
                  </span>
                </label>
                <div className="col-8">
                  <input
                    type="file"
                    name="filename"
                    id="filename"
                    accept="video/*"
                    onChange={loadFile}
                    style={{ border: errors.filename ? "1px solid red " : "" }}
                    required=""
                  />
                  {errors.filename && <p style={{ color: "red", fontWeight: 600, marginTop: "5px" }}><i>{errors.video}</i></p>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Name:</label>{" "}
                <div className="col-8">
                  <input
                    className="form-control"
                    maxLength="64"
                    name="name"
                    type="text"
                    onChange={onChange}
                    value={form.name}
                    style={{ border: errors.name ? "1px solid red " : "" }}
                    placeholder="Scenario name ..."
                  />
                  {errors.name && <p style={{ color: "red", fontWeight: 600, marginTop: "5px" }}><i>{errors.name}</i></p>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  Footage type:
                </label>
                <div className="col-8">
                  <select
                    className="custom-select select2"
                    name="calibrator_kind"
                    onChange={onChange}
                    value={form.calibrator_kind}
                  >
                    <option value="Scouting">Scouting feed</option>
                    <option value="NewScouting">New Scouting feed</option>
                    <option value="Broadcasting">Broadcast feed</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Folder:</label>{" "}
                <div className="col-8">
                  <select
                    className="custom-select select2"
                    name="folder_id"
                    onChange={onChange}
                    value={form.folder_id}
                  >
                    <option value="0">Root folder</option>
                    {folders && folders.map(folder => (
                      <option value={folder.folder_id}>
                        {folder.path ? `${folder.path}/${folder.name}` : folder.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <span
                    className="help"
                    title="Select specific server to run on. Leave blank for autoselection."
                  >
                    VT Server
                  </span>
                </label>
                <div className="col-8">
                  <select
                    className="custom-select"
                    name="server_id"
                    onChange={onChange}
                    value={form.server_id}
                    style={{ border: errors.server_id ? "1px solid red " : "" }}
                  >
                    {servers.map(server => (
                      <option key={server.server_id} value={server.server_id}>{`Name : ${server.name} -- IP : ${server.ip} `}</option>
                    ))}
                  </select>
                  {errors.server_id && <p style={{ color: "red", fontWeight: 600, marginTop: "5px" }}><i>{errors.server_id}</i></p>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Favorite:</label>
                <div className="col-8">
                  <input
                    className="form-control"
                    type="checkbox"
                    name="favorite"
                    onChange={onChange}
                    value={form.favorite}
                    checked={form.favorite}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <span
                    className="help"
                    title="Add EventDetection module data correction pass"
                  >
                    Correct
                  </span>
                </label>
                <div className="col-8">
                  <input
                    className="form-control"
                    type="checkbox"
                    name="correct"
                    onChange={onChange}
                    value={form.correct}
                    checked={form.correct}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Body Angle:</label>
                <div className="col-8">
                  <input
                    className="form-control"
                    type="checkbox"
                    name="bodyAngle"
                    onChange={onChange}
                    value={form.bodyAngle}
                    checked={form.bodyAngle}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Description:</label>{" "}
                <div className="col-8">
                  <textarea
                    className="form-control"
                    cols="50"
                    rows="4"
                    onChange={onChange}
                    value={form.description}
                    name="description"
                  ></textarea>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Tags:</label>{" "}
                <div className="col-8">
                  <div className="form-control bootstrap-tagsinput">
                    {tags.map((tag, index) => (
                      <span key={index} style={{ marginLeft: "5px" }} className="tag badge badge-info">
                        {tag}<button onClick={() => removeTag(index)} type="button" style={{ fontWeight: 700, fontSize: "13px", border: "none", backgroundColor: "transparent", color: "white" }}>x</button>
                      </span>
                    ))}
                    <input
                      type="text"
                      className="allow-enter forn-control"
                      onKeyDown={addTags}
                      style={{ border: "none", outline: "none" }}
                      name="addtags"
                    />
                  </div>
                  <input
                    name="tags"
                    type="text"
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  Upload progress:
                </label>{" "}
                <div className="col-8">
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Status:</label>{" "}
                <div className="col-8">
                  <textarea
                    readOnly
                    id="upload_status"
                    className="form-control"
                    cols="40"
                    rows="6"
                    value={statusUpload}
                    name="status"
                  ></textarea>
                </div>
              </div>

              <button type="button" onClick={onSubmit} id="upload-button" className="btn button">
                <i className="fa fa-fw fa-upload"></i> Upload
              </button>
              <Link
                to="/videotracking/tasks"
                className="button-cancel"
              >
                <i className="fa fa-fw fa-refresh"></i>&nbsp;Reset
              </Link>
              <input type="hidden" name="op" value="" />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVideo;
